<template>
  <div>

    <CRow>
      <CCol lg="12">
        <h1 class="main-header" v-translate translate-context="faq_category">Faq Category List</h1>
        <CCard class="">
          <CCardHeader>
            <div class="card-header-actions">
              <router-link :to="{name: 'faqCategory.new'}">
                <CButton block color="primary" v-translate translate-context="list.actions">Create</CButton>
              </router-link>
            </div>
          </CCardHeader>
          <CCardBody class="px-0">
            <CDataTable
                :hover="false"
                :bordered="true"
                :items="categories.results"
                :fields="fields"
                :column-filter="{external: true,lazy:true}"
                :column-filter-value="filters"
                :sorter="{external: true, resetable:true}"
                :loading="loading"
                :sorter-value="sorting"
                @update:column-filter-value="updateFilters"
                @update:sorter-value="updateSorting"
                class="custom-table custom-table--clean custom-table--has-filters"
            >
              <template #no-items-view>
                <div class="text-center">
                  <h5 v-translate>No items found</h5>
                </div>
              </template>
              <template #sorting-icon="{ state, classes }">
                <div :class="classes">
                  <CIcon name="cipCaretUp" size="lg" v-if="state === 'asc' || state === 0"></CIcon>
                  <CIcon name="cipCaretUp" size="lg" v-else></CIcon>
                </div>
              </template>
              <template #actions="{item}">
                <td class="text-right">
                  <CButton variant="outline" color="success" class="mr-2"
                           @click="$router.push({name: 'faqQuestion.list', params: {category: item.id}})"
                           v-translate translate-context="faq_category.actions"
                  >
                    Questions
                  </CButton>
                  <CButton variant="outline" color="primary" class="mr-2"
                           @click="$router.push({name: 'faqCategory.edit', params: {id: item.id}})"
                           v-translate translate-context="list.actions"
                  >
                    Edit
                  </CButton>
                  <CButton @click="onDelete(item.id)" variant="outline" color="danger"
                           v-translate translate-context="list.actions"
                  >
                    Delete
                  </CButton>
                </td>
              </template>
              <template #under-table>
                <CPagination
                    v-show="categories.pagination.num_pages > 1"
                    :activePage="categories.pagination.page"
                    @update:activePage="updatePage"
                    :pages="categories.pagination.num_pages"
                />
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>


    </CRow>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ListFaqCategory',

  data() {
    return {
      loading: true,
      fields: [
        {key: 'id', label: this.$gettextInterpolate('ID','faq_category.list.tbl'), _style: 'width:100px;'},
        {key: 'name', label: this.$gettextInterpolate('Name','faq_category.list.tbl')},
        {key: 'is_active', label: this.$gettextInterpolate('Active','faq_category.list.tbl')},
        {key: 'tags', label: this.$gettextInterpolate('Tags','faq_category.list.tbl')},
        {key: 'order', label: this.$gettextInterpolate('Order','faq_category.list.tbl')},
        {key: 'actions', label: this.$gettextInterpolate('Actions','faq_category.list.tbl'), sorter: false, filter: false}
      ],
      pagination: {
        page_size: 20,
        page: 1,
      },
      sorting: {
        column: null,
        asc: false
      },
      filters: {},
    }
  },
  async mounted() {
    await this.fetchList()
  },
  computed: {
    ...mapGetters('faq', [
      'categories',
    ]),
  },
  methods: {
    ...mapActions('faq', [
      'fetchCategoryList',
      'deleteCategory'
    ]),
    async fetchList() {
      this.loading = true
      await this.fetchCategoryList({
        filters: this.filters,
        pagination: this.pagination,
        sorting: this.sorting
      })
      this.pagination.page = this.categories.pagination.page
      this.loading = false
    },
    updateFilters(filters) {
      this.filters = filters
      this.pagination.page = 1
      this.fetchList()
    },
    updateSorting(sorting) {
      this.sorting = sorting
      this.fetchList()
    },
    updatePage(page) {
      this.pagination.page = page
      this.fetchList()
    },
    async onDelete(id) {
      // TODO: Trovare un componente per fare i confirm
      if (!confirm('Are you sure?')) {
        return
      }
      await this.deleteCategory(id)
      await this.fetchList()
    }
  }
}
</script>

<style scoped>

</style>